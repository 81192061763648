<template>
  <div class="content-wrapper">
    <div class="row content-header">
      <div class="content-header-left mb-2 col-12">
        <div class="row breadcrumbs-top">
          <div class="col-12 d-flex align-items-center justify-content-between">
            <h2 class="content-header-title float-left pr-1 mb-0">
              Banner
            </h2>
          </div>
        </div>
      </div>
    </div>
    <div class="content-body">
      <div class="row match-height">
        <div class="col-12">
          <div
              data-v-5cff0742=""
              class="card"
          ><!----><!---->
            <div
                data-v-5cff0742=""
                class="card-header"
            />
            <div
                data-v-5cff0742=""
                class="card-body"
            ><!----><!---->
              <form
                  data-v-5cff0742=""
                  class=""
              >
                <div
                    data-v-5cff0742=""
                    class="row"
                >
                  <div
                      data-v-5cff0742=""
                      class="col-12"
                  >
                    <div
                        role="group"
                        class="form-row form-group"
                    ><label
                        class=" col-form-label"
                    >
                    </label>
                      <div class="col">
                        <img style="width: 200px;object-fit: cover" :src="this.srcFile" alt="">
                      </div>
                    </div>
                  </div>
                </div>
                <div
                    data-v-5cff0742=""
                    class="row"
                >
                  <div
                      data-v-5cff0742=""
                      class="col-12"
                  >
                    <div
                        role="group"
                        class="form-row form-group"
                    ><label
                        for=""
                        class=" col-form-label"
                    >
                    </label>
                      <div class="col-3">
                        <div class="file-upload">
                          <div class="file-select">
                            <div class="file-select-button" id="fileName">Choose File</div>
                            <div class="file-select-name" v-html="this.nameFile"></div>
                            <input type="file" name="chooseFile" id="file" @change="onFileChange( $event)">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                      data-v-5cff0742=""
                      class="col-12"
                  >
                    <div
                        role="group"
                        class="form-row form-group"
                    ><label
                        class=" col-form-label"
                    >
                    </label>
                      <div class="col">
                        <button
                            data-v-5cff0742=""
                            type="button"
                            class="btn mr-1 btn-primary"
                            @click.prevent="submitUpdateBanner()"
                        >
                          Upload
                        </button>
                      </div>
                    </div>


                  </div>
                </div>
              </form>
            </div><!----><!----></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex/dist/vuex.esm.browser'

export default {
  name: 'Banner',
  data() {
    return {
      profile: null,
      file: '',
      srcFile: '',
      nameFile: 'No file chosen...',
    }
  },
  created() {
    this.getMe({}).then(res => {
      console.log('GET_ME', res)
      this.profile = res.data.data
      this.srcFile = res.data.data.image.path
    }).catch(e => {
      console.log(e)
    })
  },
  methods: {
    ...mapActions(['getMe', 'updateProFile']),
    onFileChange(event) {
      // eslint-disable-next-line prefer-destructuring
      this.file = event.target.files[0]
      this.nameFile = event.target.files[0].name
      event.srcElement.parentElement.children[0].textContent = event.target.files[0].name
    },
    submitUpdateBanner() {
      if (this.file === ''){
        alert('Choose File to upload !')
        return false;
      }
      let profile = this.profile;
      profile.image_id = this.file
      this.updateProFile(profile)
    },
  },
}
</script>

<style scoped>

</style>
